.content {
  margin: 12px;
  height: 100%;
}

.content-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* TODO : Make it native Flexbox */

.content-flex {
  display: flex;
  gap: 16px;
}

.content-flex-row {
  flex-direction: row;
}

.content-flex-justify-content-sb {
  justify-content: space-between;
}

.content-flex-justify-content-middle {
  justify-content: center;
}

.content-flex-wrap {
  flex-wrap: wrap;
}

.content-scroll {
  overflow: scroll;
}

.content::-webkit-scrollbar {
  display: none;
}
