.page-loader{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 950;
}

.transparent {
    background-color: rgba(0,0,0,0.1);
}

.page-loader .container{
    padding:16px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0px 8px rgba(0,0,0,0.2);
}