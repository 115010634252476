.input {
  margin: 8px;
  padding: 4px 10px;
  color: var(--app-theme);
  border: 2px solid var(--app-theme);
  background-color: transparent;
  height: 30px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
  width: 75%;
}

.input::placeholder {
  color: var(--app-theme);
  font-weight: 600;
}

.input:focus {
  outline: none;
}

.button {
  position: relative;
  border: none;
  background: none;
  outline: none;
  color: black;
  mix-blend-mode: screen;
  height: 36px;
  font-weight: 600;
  padding: 0 16px;
  cursor: pointer;
}

.button::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--white);
  border-radius: 0.3em;
  content: "";
  mix-blend-mode: color-burn;
}

.controls-container {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
