.table {
  width: 100%;
  table-layout: fixed;
  color: var(--app-theme);
}

.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}
.tbl-content {
  height: 300px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
th {
  padding: 20px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  font-size: 12px;
}
