.page {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page::-webkit-scrollbar {
  display: none;
}

.opaque {
  background-color: white;
}
