.app-loader{
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 1000;
    top:0;
}

.transparent {
    background-color: rgba(0,0,0,0.1);
}

.app-loader .container{
    padding:16px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0px 8px rgba(0,0,0,0.2);
}