.app-button {
  border: 2px solid var(--app-theme);
  color: var(--app-theme);
  padding: 12px 14px;
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.app-button:hover {
  background-color: var(--app-theme);
  color: var(--white);
}
